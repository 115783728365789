import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import siteSettingsSlice from './slices/siteSettingsSlice'
import clickOutSideSlice from './slices/clickOutSideSlice'
import dashboard from './slices/dashBoardSlice'
import addUserType from './slices/userAddSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        siteSettingsSlice,
        clickOutSideSlice,
        dashboard,
        addUserType,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
