import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userType: 1,
};

const userAddSlice = createSlice({
  name: "user add",
  initialState,
  reducers:{
    addUserType:(state,action)=>{
        state.userType = action.payload
    }
  }
});

export const {addUserType} = userAddSlice.actions
export default userAddSlice.reducer