import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard/*`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  // {
  //   key: "library.list",
  //   path: `${APP_PREFIX_PATH}/library`,
  //   component: React.lazy(() =>
  //     import("views/app-views/pages/Library/Library")
  //   ),
  // },
  {
    key: "library.update",
    path: `${APP_PREFIX_PATH}/library/update/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/Library/LibraryAdd")
    ),
  },
  {
    key: "library.add",
    path: `${APP_PREFIX_PATH}/library/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/Library/LibraryAdd")
    ),
  },
  // admin=========>
  {
    key: "user.admins",
    path: `${APP_PREFIX_PATH}/members-management/admin/adminList`,
    component: React.lazy(() =>
      import("views/app-views/pages/admin/AdminList")
    ),
  },
  {
    key: "user.admins.add",
    path: `${APP_PREFIX_PATH}/members-management/admin/admin-add`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.edit",
    path: `${APP_PREFIX_PATH}/members-management/admin/admin-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.profile",
    path: `${APP_PREFIX_PATH}/members-management/admin/admin-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/AdminProfile")
    ),
  },

  // user=======>

  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/members-management/user/userlist`,
    component: React.lazy(() => import("views/app-views/pages/user/userList")),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/members-management/user/user-add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.edit",
    path: `${APP_PREFIX_PATH}/members-management/user/user-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.profile",
    path: `${APP_PREFIX_PATH}/members-management/user/profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/UserProfile")
    ),
  },

  // coach=====>

  {
    key: "user.coaches",
    path: `${APP_PREFIX_PATH}/members-management/coach-add`,
    component: React.lazy(() =>
      import("views/app-views/pages/coaches/CoachesAdd")
    ),
  },
  {
    key: "user-coaches-list",
    path: `${APP_PREFIX_PATH}/members-management/coaches/coach-list`,
    component: React.lazy(() =>
      import("views/app-views/pages/coaches/CoachesList")
    ),
  },
  {
    key: "user.coaches.edit",
    path: `${APP_PREFIX_PATH}/members-management/coach-edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/coaches/CoachesAdd")
    ),
  },
  {
    key: "user.coaches.profile",
    path: `${APP_PREFIX_PATH}/members-management/coaches/profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/CoachesProfile")
    ),
  },

  // media==========>

  {
    key: "media.list",
    path: `${APP_PREFIX_PATH}/media/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/media/MediaList")
    ),
  },

  // Activity===> Challenges (Sakil)

  {
    key: "activity.challenges.list",
    path: `${APP_PREFIX_PATH}/challenges`,
    component: React.lazy(() =>
      import("views/app-views/pages/challenges/ChallengesList")
    ),
  },
  {
    key: "activity.updateChallenges.edit",
    path: `${APP_PREFIX_PATH}/activity/challenges/update/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/challenges/addChallenge/index")
    ),
  },
  {
    key: "activity.addChallenges.add",
    path: `${APP_PREFIX_PATH}/activity/challenges/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/challenges/addChallenge")
    ),
  },

  // Activity===> Cards (Sakil)

  {
    key: "activity.cards.list",
    path: `${APP_PREFIX_PATH}/cards`,
    component: React.lazy(() => import("views/app-views/pages/cards")),
  },
  {
    key: "activity.addCards.add",
    path: `${APP_PREFIX_PATH}/activity/cards/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/cards/addCard/AddCard")
    ),
  },
  {
    key: "activity.updateCard.edit",
    path: `${APP_PREFIX_PATH}/activity/cards/update/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/cards/updateCard/UpdateCard")
    ),
  },
  // {
  //   key: "activity.goal.list",
  //   path: `${APP_PREFIX_PATH}/goals`,
  //   component: React.lazy(() =>
  //     import("views/app-views/pages/goals/GoalsLists")
  //   ),
  // },

  // Activity===> Badges (Sakil)

  {
    key: "activity.badges.list",
    path: `${APP_PREFIX_PATH}/badges`,
    component: React.lazy(() =>
      import("views/app-views/pages/badges/BadgesList")
    ),
  },
  {
    key: "activity.badges.add",
    path: `${APP_PREFIX_PATH}/badges/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/badges/AddBadges")
    ),
  },
  {
    key: "activity.badges.edit",
    path: `${APP_PREFIX_PATH}/badges/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/badges/AddBadges")
    ),
  },





  // CMS Management===> Pages (Sakil)

  {
    key: "cms.pages.list",
    path: `${APP_PREFIX_PATH}/cms/pages`,
    component: React.lazy(() =>
      import("views/app-views/pages/sitePages/SitePages")
    ),
  },
  {
    key: "cms.updatePages.edit",
    path: `${APP_PREFIX_PATH}/cms/pages/update/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/sitePages/editPage/EditPage")
    ),
  },

  // CMS Management===> Email template (Sakil)

  {
    key: "cms.emailTemplate.list",
    path: `${APP_PREFIX_PATH}/cms/emailTemplate`,
    component: React.lazy(() =>
      import("views/app-views/pages/emailTemplate/index")
    ),
  },
  {
    key: "cms.emailTemplate.update",
    path: `${APP_PREFIX_PATH}/cms/emailTemplate/update/:id`,
    component: React.lazy(() =>
      import(
        "views/app-views/pages/emailTemplate/UpdateEmailTemplates/UpdateEmailTemplate"
      )
    ),
  },
  // cms  FAQS---->>
  {
    key: "cms.faqs.list",
    path: `${APP_PREFIX_PATH}/cms/faqs`,
    component: React.lazy(() =>
      import("views/app-views/pages/faq")
    ),
  },
  {
    key: "cms.faqs.add",
    path: `${APP_PREFIX_PATH}/cms/faqs-add`,
    component: React.lazy(() =>
      import("views/app-views/pages/faq/AddFaqs")
    ),
  },
  {
    key: "cms.faqs.edit",
    path: `${APP_PREFIX_PATH}/cms/faqs/faqs-edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/faq/AddFaqs")
    ),
  },
  // Banner====>>
  {
    key: "cms.banners.list",
    path: `${APP_PREFIX_PATH}/cms/banners`,
    component: React.lazy(() =>
      import("views/app-views/pages/Banners")
    ),
  },
  {
    key: "cms.banners.add",
    path: `${APP_PREFIX_PATH}/cms/banners-add`,
    component: React.lazy(() =>
      import("views/app-views/pages/Banners/addBanner")
    ),
  },
  {
    key: "cms.banners.edit",
    path: `${APP_PREFIX_PATH}/cms/banner-edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/Banners/addBanner")
    ),
  },
  // Enquiries
  {
    key: "cms.enquiries.list",
    path: `${APP_PREFIX_PATH}/cms/enquiries`,
    component: React.lazy(() =>
      import("views/app-views/pages/Enquiries")
    ),
  },
  // CMS Management===> Settings (Sakil)

  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/cms/pricing`,
    component: React.lazy(() =>
      import("views/app-views/pages/pricing/PricingList")
    ),
  },
  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/cms/add/pricing`,
    component: React.lazy(() =>
      import("views/app-views/pages/pricing/add/PricingAdd")
    ),
  },
  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/cms/edit/pricing/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/pricing/add/PricingAdd")
    ),
  },

  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/cms/settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },

    // CMS Management===> Guidelines (Sakil)

    // {
    //   key: "cms.guidelines.list",
    //   path: `${APP_PREFIX_PATH}/cms/guidelines`,
    //   component: React.lazy(() =>
    //     import("views/app-views/pages/guidelines/Guidelines")
    //   ),
    // },

  //Change Password ===>(Sakil)
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },

  //Edit Profile ===>(Sakil)
  {
    key: "profile.edit",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },

  //Billing ===>(Sakil)
  {
    key: "profile.billing",
    path: `${APP_PREFIX_PATH}/billing`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/Billing")
    ),
  },

  //Notification ===>(Sakil)
  {
    key: "profile.notificatiion",
    path: `${APP_PREFIX_PATH}/notifications`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/Notification")
    ),
  },
];
