import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {title:"Users",count:0},
  task: {title:"Tasks",count:0},
  googleTask: {title:"Google Tasks",count:0},
  NewMemberData: {
    loaded:false,
    data:[]
  },
};

const dashBoardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    userDataDispatch: (state, action) => {
      state.userData = action.payload;
    },
    newMemberDataDispatch: (state, action) => {
      state.NewMemberData = action.payload;
    },
    getTotalTasks:(state,action)=>{
      state.task.count=action.payload
    },
    getTotalGoogleTask:(state,action)=>{
      state.googleTask.count=action.payload
    }
  },
});

export const { userDataDispatch, newMemberDataDispatch,getTotalTasks,getTotalGoogleTask } =
  dashBoardSlice.actions;
export default dashBoardSlice.reducer;
