import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const siteSettingsSlice = createSlice({
  name: "site details",
  initialState,
  reducers:{
    siteSettingsDispatch:(state,action)=>{
        state.data = action.payload
    }
  }
});

export const {siteSettingsDispatch} = siteSettingsSlice.actions
export default siteSettingsSlice.reducer