import { apiRequest } from "utils/async/apiUtils"

export const authLogin = async params => {
  try {
    const res = await apiRequest(
      'post',
      'login',
      {
        body: {
          email: params.email,
          password: params.password
        }
      }
    )

    const data = res.data

    // localStorage.setItem('accessToken', data.accessToken)
    localStorage.setItem('refreshToken', data.refreshToken)

    return data
  } catch (err) {
    throw new Error(err.message)
  }
}

export const verifyToken = async (setAuth = () => {}) => {
  try {
    setAuth({ loaded: false, authenticated: false })

    const res = await apiRequest(
      'post',
      'verify-token',
      {}
    )

    const data = res.data

    setAuth({ loaded: true, authenticated: true })

    return data
  } catch (err) {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('refreshToken')

    window.location.href = '/auth/login'
    
    throw new Error(err.message)
  }
}