import { apiRequest } from "utils/async/apiUtils";

export const loadSettingDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "setting/detail");

    const data = res.data;
    setResData(data);
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};

export const editSettings = async (data, setIsLoaded = () => {}) => {
  setIsLoaded(false);
  try {
    const res = await apiRequest(
      "put",
      "setting/update",
      {
        body: {
          title: data.title,
          description: data.description,
          email: data.email,
          phone: data.phone,
          address: data.address,
          logo: data.fileList,
          favicon: data.favIcon,
          specialDay: data?.specialDay,
          streakAndBadge: data?.streakAndBadge,
          systemMisses: data?.systemMisses,
        },
      },
      "multipart/form-data"
    );
    setIsLoaded(true);
    return res;
  } catch (error) {
    setIsLoaded(true);
    return error.response;
  }
};
